import React, { Component } from "react";
import { getCustomer } from "redux/actions/customer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

export class PersonalDetailsClass extends Component {
  state = {};

  componentDidMount() {}

  render() {
    let { customerDetail = {}, t } = this.props;
    console.log(customerDetail, "asdasdfasdfasdf === sdfasdfagf");
    let {
      id,
      cust_name,
      cust_address,
      last_visit,
      upcoming_appointments,
      cust_dob,
      cust_phone2,
      Cust_sexesid,
      cust_email,
      join_date,
      total_invoice,
      service_package,
      prepaid,
      products,
      credit_note,
      voucher,
      appointment,
    } = customerDetail;
    return (
      <>
        <div className="customer-details">
          <div className="row pt-5">
            <div className="col-3">
              <p className="customer-detail-desc pb-4">
                {t(`Contact Numbers`)}
              </p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{cust_phone2}</p>
            </div>
            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Email Address`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{cust_email}</p>
            </div>
            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Address`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{cust_address}</p>
            </div>
            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Gender`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">
                {Cust_sexesid === 1 ? "Male" : "Female"}
              </p>
            </div>

            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`DOB`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{cust_dob}</p>
            </div>
            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Join Date`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{join_date}</p>
            </div>
            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Total Invoices`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{total_invoice}</p>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-3">
              <p className="customer-detail-desc pb-4">
                {t(`Service Packages`)}
              </p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{service_package}</p>
            </div>
            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Prepaid`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{prepaid}</p>
            </div>
            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Product`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{products}</p>
            </div>
            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Credit Notes`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{credit_note}</p>
            </div>

            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Voucher`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{voucher}</p>
            </div>
            <div className="col-3">
              <p className="customer-detail-desc pb-4">{t(`Appointments`)}</p>
            </div>
            <div className="col-9">
              <p className="customer-detail-text pb-4">{appointment}</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  customerDetail: state.customer.customerDetail,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCustomer,
    },
    dispatch
  );
};

export const PersonalDetails = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsClass)
);
