import React from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  Text,
  Font,
} from "@react-pdf/renderer";
import InvoiceItemsTable from "./InvoiceItemsTable";
import logo from "assets/images/logo.png";
import CustomerHeader from "./CustomerHeader";
import CustomerFooter from "./CustomerFooter";

import {
  AccountMainInfo,
  AccountDetailInfo,
  ProductMainInfo,
  ProductDetailMain,
} from "./TreatmentAccount";
import { PrepaidInfo } from "./TreatmentAccount";
import { PrepaidDetailInfo } from "./TreatmentAccount/DetailPrepaid";

import allfont from "../../../../assets/fonts/ZCOOL_XiaoWei/ZCOOLXiaoWei-Regular.ttf";
import { CreditMainInfo } from "./TreatmentAccount/CreditReport";

Font.register({
  family: "ZCOOL XiaoWei",
  src: allfont,
});
const styles = StyleSheet.create({
  page: {
    fontFamily: "ZCOOL XiaoWei",
    fontSize: 11,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    lineHeight: 1.5,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export class Invoice extends React.Component {
  render() {
    let { accountHeader, TableList, Flag, landscape } = this.props;
    console.log(accountHeader, "propsaccountheadervaluesresult");
    return (
      <Document file="somefile.pdf">
        <Page
          size="A4"
          orientation={landscape ? "landscape" : "portrait"}
          style={styles.page}
        >
          <CustomerHeader accountHeader={accountHeader} />
          {Flag == 1 ? (
            <AccountMainInfo accountHeader={accountHeader} />
          ) : Flag == 2 ? (
            <CreditMainInfo accountHeader={accountHeader} />
          ) : Flag == 3 ? (
            <PrepaidInfo accountHeader={accountHeader} />
          ) : Flag == 5 ? (
            <ProductMainInfo accountHeader={accountHeader} />
          ) : Flag == 6 ? (
            <ProductDetailMain accountHeader={accountHeader} />
          ) : Flag == 4 ? (
            <PrepaidDetailInfo accountHeader={accountHeader} />
          ) : (
            <AccountDetailInfo accountHeader={accountHeader} />
          )}

          <InvoiceItemsTable TableList={TableList} Flag={Flag} />
          <CustomerFooter />
        </Page>
      </Document>
    );
  }
}
export default Invoice;
