const routers = [
  {
    path: "/",
    auth: false,
    exact: true,
    redirect: "/auth/login",
  },
  {
    component: "AuthLayout",
    path: "/auth",
    redirect: "/auth/login",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Login",
        path: "/login",
        auth: false,
        exact: false,
      },
      {
        component: "ForgotPassword",
        path: "/forgotPassword",
        auth: false,
        exact: false,
      },
      {
        component: "ChangePassword",
        path: "/resetPassword/:name",
        auth: false,
        exact: false,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/admin",
    // redirect: '/admin/dashboard',
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Dashboard",
        path: "/dashboard",
        auth: false,
        exact: true,
      },
      {
        component: "Billing",
        path: "/billing",
        auth: false,
        exact: true,
      },
      {
        component: "CustomerReceipt",
        path: "/billing/print",
        auth: false,
        exact: true,
      },

      {
        component: "CustomerReceipt",
        path: "/billing/print/bill/:id",
        auth: false,
        exact: true,
      },
      {
        component: "TransactionHistory",
        path: "/transactionhistory",
        auth: false,
        exact: true,
      },
      {
        component: "TransactionReceipt",
        path: "/transactionhistory/print",
        auth: false,
        exact: true,
      },

      {
        component: "TransactionReceipt",
        path: "/transactionhistory/print/bill/:id",
        auth: false,
        exact: true,
      },
      {
        component: "CartHome",
        path: "/cart/old",
        auth: false,
        exact: true,
      },
      {
        component: "CartNew",
        path: "/cart",
        auth: false,
        exact: true,
      },
      {
        component: "TreatmentDone",
        path: "/cart/treatment-done/:id",
        auth: false,
        exact: true,
      },
      {
        component: "BillOps",
        path: "/cart/bill-ops",
        auth: false,
        exact: true,
      },
      {
        component: "Catalog",
        path: "/catalog",
        auth: false,
        exact: true,
      },
      {
        component: "Payment",
        path: "/payment",
        auth: false,
        exact: true,
      },
      {
        component: "Payment",
        path: "/payment/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ListSalons",
        path: "/salons",
        auth: false,
        exact: true,
      },
      {
        component: "CreateSalon",
        path: "/salons/add",
        auth: false,
        exact: true,
      },
      {
        component: "CreateSalon",
        path: "/salons/:id/editSaloon",
        auth: false,
        exact: true,
      },
      {
        component: "SalonDetails",
        path: "/salons/:id/salonDetails",
        auth: false,
        exact: true,
      },
      {
        component: "ListStaff",
        path: "/staff",
        auth: false,
        exact: true,
      },
      {
        component: "AddStaff",
        path: "/staff/add",
        auth: false,
        exact: true,
      },
      {
        component: "AddStaff",
        path: "/staff/:id/editStaff",
        auth: false,
        exact: true,
      },
      {
        component: "StaffDetails",
        path: "/staff/:id/staffDetails",
        auth: false,
        exact: true,
      },
      {
        component: "StaffAvailability",
        path: "/staff/availability",
        auth: false,
        exact: true,
      },
      {
        component: "ListStaffPlus",
        path: "/staffplus",
        auth: false,
        exact: true,
      },
      {
        component: "StaffPlusDetails",
        path: "/staffPlus/:id/staffDetails",
        auth: false,
        exact: true,
      },
      {
        component: "StaffPlusAvailability",
        path: "/staffPlus/availability",
        auth: false,
        exact: true,
      },
      {
        component: "StaffSkillList",
        path: "/staffplus/skills",
        auth: false,
        exact: true,
      },
      {
        component: "AddStaffSkill",
        path: "/staffplus/skills/add",
        auth: false,
        exact: true,
      },
      {
        component: "AddStaffPlus",
        path: "/staffplus/add",
        auth: false,
        exact: true,
      },
      {
        component: "StaffSchedule",
        path: "/staffplus/schedule",
        auth: false,
        exact: true,
      },
      {
        component: "SecurityAuthorization",
        path: "/staffplus/authorization",
        auth: false,
        exact: true,
      },
      {
        component: "AddStaffPlus",
        path: "/staffplus/:id/editStaff",
        auth: false,
        exact: true,
      },
      {
        component: "EmployeeInfo",
        path: "/staffplus/:id/empInfo",
        auth: false,
        exact: true,
      },
      {
        component: "ListCustomerPlus",
        path: "/customerplus",
        auth: false,
        exact: true,
      },
      {
        component: "Settings",
        path: "/customerplus/settings",
        auth: false,
        exact: true,
      },
      {
        component: "CustomerPlusEditLayout",
        path: "/customerplus/settings/layout",
        auth: false,
        exact: true,
      },
      {
        component: "LoyaltyPointsManagement",
        path: "/customerplus/:id/lpmanagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddManualPoints",
        path: "/customerplus/:id/lpmanagement/:type",
        auth: false,
        exact: true,
      },
      {
        component: "LoyaltyPointsManagementSettings",
        path: "/customerplus/lpmanagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddRewardPolicy",
        path: "/customerplus/lpmanagement/addreward",
        auth: false,
        exact: true,
      },
      {
        component: "AddRewardPolicy",
        path: "/customerplus/lpmanagement/:id/editreward",
        auth: false,
        exact: true,
      },
      {
        component: "AddRedeemPolicy",
        path: "/customerplus/lpmanagement/addredeem",
        auth: false,
        exact: true,
      },
      {
        component: "AddRedeemPolicy",
        path: "/customerplus/lpmanagement/:id/editredeem",
        auth: false,
        exact: true,
      },
      {
        component: "AddCustomerPlus",
        path: "/customerplus/add",
        auth: false,
        exact: true,
      },
      {
        component: "AddCustomerPlus",
        path: "/customerplus/:id/editCustomer",
        auth: false,
        exact: true,
      },
      {
        component: "CustomerDetailsPlus",
        path: "/customerplus/:id/details",
        auth: false,
        exact: true,
      },
      {
        component: "AccountDetails",
        path: "/customerplus/:id/account",
        auth: false,
        exact: true,
      },
      {
        component: "InvoiceHistorys",
        path: "/customerplus/:id/invoice",
        auth: false,
        exact: true,
      },
      {
        component: "HoldSections",
        path: "/customerplus/:id/hold",
        auth: false,
        exact: true,
      },
      {
        component: "TreatmentDetails",
        path: "/customerplus/:id/treatment",
        auth: false,
        exact: true,
      },
      {
        component: "TreatmentCourseDetails",
        path: "/customerplus/:id/treatment/:treatmentId/detail",
        auth: false,
        exact: true,
      },
      {
        component: "ListCustomer",
        path: "/customer",
        auth: false,
        exact: true,
      },
      {
        component: "AddCustomer",
        path: "/customer/add",
        auth: false,
        exact: true,
      },
      {
        component: "AddCustomer",
        path: "/customer/:id/editCustomer",
        auth: false,
        exact: true,
      },
      {
        component: "CustomerDetails",
        path: "/customer/:id/details",
        auth: false,
        exact: true,
      },
      {
        component: "NewListAppointment",
        path: "/newappointmentfullscreen",
        auth: false,
        exact: true,
      },
      {
        component: "MobileListAppointment",
        path: "/mobileappointment",
        auth: false,
        exact: true,
      },

      // {
      //   component: "ListAppointment",
      //   path: "/appointment",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "NewListAppointment",
        path: "/newappointment",
        auth: false,
        exact: true,
      },
      // {
      //   component: "CreateAppointment",
      //   path: "/appointment/create",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "NewCreateAppointment",
        path: "/newappointment/create",
        auth: false,
        exact: true,
      },
      {
        component: "InventoryMainPage",
        path: "/inventory",
        auth: false,
        exact: true,
      },
      {
        component: "SmtpSettings",
        path: "/settings",
        auth: false,
        exact: true,
      },
      {
        component: "SmtpSettings",
        path: "/settings/smtpsettings",
        auth: false,
        exact: true,
      },
      {
        component: "SmtpCreate",
        path: "/settings/smtpsettings/add",
        auth: false,
        exact: true,
      },
      {
        component: "SmtpCreate",
        path: "/settings/smtpsettings/:id/edit",
        auth: false,
        exact: true,
      },
      {
        component: "SmtpDetails",
        path: "/settings/smtpsettings/:id/details",
        auth: false,
        exact: true,
      },
      {
        component: "SetupTransaction",
        path: "/settings/setuptransaction",
        auth: false,
        exact: true,
      },
      {
        component: "SetupTransCreate",
        path: "/settings/setuptransaction/add",
        auth: false,
        exact: true,
      },
      {
        component: "SetupTransCreate",
        path: "/settings/setuptransaction/:id/edit",
        auth: false,
        exact: true,
      },
      {
        component: "SetupTransDetails",
        path: "/settings/setuptransaction/:id/details",
        auth: false,
        exact: true,
      },
      // {
      //   component: "Treatment",
      //   path: "/appointment/create/treatment",
      //   auth: false,
      //   exact: true,
      // },
      // {
      //   component: "SelectTreatment",
      //   path: "/appointment/create/select-treatment",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "NewSelectTreatment",
        path: "/newappointment/create/select-treatment",
        auth: false,
        exact: true,
      },
      // {
      //   component: "Payment",
      //   path: "/payment/appointment/:id",
      //   auth: false,
      //   exact: true,
      // },
      // {
      //   component: "AppointmentDetail",
      //   path: "/appointment/:id/detail",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "NewAppointmentDetail",
        path: "/newappointment/:id/detail",
        auth: false,
        exact: true,
      },
      {
        component: "ListService",
        path: "/service",
        auth: false,
        exact: true,
      },
      {
        component: "CreateService",
        path: "/service/add",
        auth: false,
        exact: true,
      },
      {
        component: "CreateService",
        path: "/service/:id/editServices",
        auth: false,
        exact: true,
      },
      {
        component: "ServiceDetails",
        path: "/service/:id/serviceDetails",
        auth: false,
        exact: true,
      },
      {
        component: "ListProduct",
        path: "/product",
        auth: false,
        exact: true,
      },
      {
        component: "CreateProduct",
        path: "/product/add",
        auth: false,
        exact: true,
      },
      {
        component: "ProductDetails",
        path: "/product/productDetails",
        auth: false,
        exact: true,
      },
      {
        component: "Review",
        path: "/review",
        auth: false,
        exact: true,
      },
      {
        component: "DayEndReport",
        path: "/DayEndReport",
        auth: false,
        exact: true,
      },

      {
        component: "viewUserDetail",
        path: "/users/viewDetail",
        auth: false,
        exact: true,
      },
      {
        component: "Tournaments",
        path: "/tournaments",
        auth: false,
        exact: true,
      },
      {
        component: "ViewTournamentDetails",
        path: "/tournaments/tournamentDetail",
        Auth: false,
        exact: true,
      },
      {
        component: "CompletedTournamentDetail",
        path: "/tournaments/completedtournamentDetail",
        Auth: false,
        exact: true,
      },
      {
        component: "CreateTournament",
        path: "/tournaments/createtournament",
        Auth: false,
        exact: true,
      },
      {
        component: "BroadcastMessage",
        path: "/broadcastmessage",
        auth: false,
        exact: true,
      },
      {
        component: "Messages",
        path: "/broadcastmessage/messages",
        auth: false,
        exact: true,
      },
      {
        component: "Backend",
        path: "/backend",
        auth: false,
        exact: true,
      },
      {
        component: "TCM",
        path: "/TCM",
        auth: false,
        exact: true,
      },
      {
        component: "TCMCustomerList",
        path: "/TCM/TCMCustomerList",
        auth: false,
        exact: true,
      },
      {
        component: "TCMPaymentList",
        path: "/TCM/TCMPaymentList",
        auth: false,
        exact: true,
      },
      {
        component: "DataEntry",
        path: "/backend/itemdataentrys",
        auth: false,
        exact: true,
      },
      {
        component: "Inventory",
        path: "/backend/inventory",
        auth: false,
        exact: true,
      },
      {
        component: "Division",
        path: "/backend/itemclassification/division",
        auth: false,
        exact: true,
      },
      {
        component: "Departmentlist",
        path: "/backend/itemclassification/department",
        auth: false,
        exact: true,
      },
      {
        component: "Departmentdata",
        path: "/backend/itemclassification/departmentdataentry",
        auth: false,
        exact: true,
      },
      {
        component: "Itemclassdata",
        path: "/backend/itemclassification/classdataentry",
        auth: false,
        exact: true,
      },
      {
        component: "Branddata",
        path: "/backend/itemclassification/branddataentry",
        auth: false,
        exact: true,
      },
      {
        component: "CommissionData",
        path: "/backend/itemclassification/commissiondataentry",
        auth: false,
        exact: true,
      },
      {
        component: "Itemrangeproduct",
        path: "/backend/itemclassification/range/product",
        auth: false,
        exact: true,
      },
      {
        component: "Itemrangeservice",
        path: "/backend/itemclassification/range/service",
        auth: false,
        exact: true,
      },
      {
        component: "Itemrangevoucher",
        path: "/backend/itemclassification/range/voucher",
        auth: false,
        exact: true,
      },
      {
        component: "Itemrangeprepaid",
        path: "/backend/itemclassification/range/prepaid",
        auth: false,
        exact: true,
      },
      {
        component: "Itemrangecompound",
        path: "/backend/itemclassification/range/compound",
        auth: false,
        exact: true,
      },
      {
        component: "Equipmentdetails",
        path: "/backend/appointment/equipemt",
        auth: false,
        exact: true,
      },
      {
        component: "Bookingstatuslist",
        path: "/backend/appointment/bookingstatus",
        auth: false,
        exact: true,
      },
      {
        component: "Secondarystatuslist",
        path: "/backend/appointment/secondarystatus",
        auth: false,
        exact: true,
      },
      {
        component: "Customermasterdetails",
        path: "/backend/customermaster/CustomerClass",
        auth: false,
        exact: true,
      },
      {
        component: "Vendordetails",
        path: "/backend/vendore/vendoredetails",
        auth: false,
        exact: true,
      },

      {
        component: "ListPO",
        path: "/po",
        auth: false,
        exact: true,
      },
      {
        component: "AddPO",
        path: "/po/add",
        auth: false,
        exact: true,
      },
      {
        component: "AddPO",
        path: "/po/:id/editPO",
        auth: false,
        exact: true,
      },
      {
        component: "ListQuotation",
        path: "/quotation",
        auth: false,
        exact: true,
      },
      {
        component: "AddQuotation",
        path: "/quotation/add",
        auth: false,
        exact: true,
      },
      {
        component: "AddQuotation",
        path: "/quotation/:id/editQuotation",
        auth: false,
        exact: true,
      },
      {
        component: "ListProject",
        path: "/project",
        auth: false,
        exact: true,
      },
      {
        component: "AddProject",
        path: "/project/add",
        auth: false,
        exact: true,
      },
      {
        component: "AddProject",
        path: "/project/:id/editProject",
        auth: false,
        exact: true,
      },

    ],
  },
];

export default routers;
