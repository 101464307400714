import React, { Component } from "react";
import "./style.scss";
import {
  NormalButton,
  NormalInput,
  NormalSelect,
  InputSearch,
  NormalModal,
  TableWrapper,
  NormalTextarea,
} from "component/common";
import { dateFormat } from "service/helperFunctions";
import _ from "lodash";
import { history } from "helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getCommonApi,
  updateForm,
  commonDeleteApi,
  commonPatchApi,
  commonCreateApi,
} from "redux/actions/common";
import SimpleReactValidator from "simple-react-validator";
import {
  Treatment,
  Payment,
  EditCart,
  TreatmentDone,
  PackageCart,
} from "./cart/index";
import closeIcon from "assets/images/close.png";

import add from "assets/images/add.png";
import minus from "assets/images/minus.png";
// import Discount from './cart/discount';
import { EditDiscount } from "./cart/editDiscount";
import { Topup } from "./topup/topup";
import { Toast } from "service/toast";
import { ProductDetailsPopup } from "./cart/productDetailsPopup";
import { ItemDiscountPopup } from "./cart/ItemDiscountPopup";
import { ItemStatusPopup } from "./cart/itemStatusPopup";
import { StaffSelectionPopup } from "./cart/staffSelectionPopup";
import { CoursePopup } from "./cart/CoursePopup";
import { PrepaidPopup } from "./cart/PrepaidPopup";
import packageIcon from "assets/images/packing-icon.png";
import { withTranslation } from "react-i18next";

import tdIcon from "assets/images/tdIcon.png";
import topupIcon from "assets/images/topupIcon.png";
import billOpsIcon from "assets/images/billOpsIcon.png";
import profileIcon from "assets/images/profileIcon.png";
import exchangeIcon from "assets/images/exchangeIcon.png";
import clearAllIcon from "assets/images/clearAllIcon.png";
import checkoutIcon from "assets/images/checkoutIcon.png";
import TransactionIcon from "assets/images/TransactionIcon.png";

export class CartNewClass extends Component {
  state = {
    isOpen: false,
    currentIndex: -1,
    cartList: [],
    cartData: {},
    formFields: {
      custName: "",
    },
    outletList: [{ label: "name", value: "id" }],
    isOpenPayment: false,
    isOpenEditcart: false,
    isOpenPackage: false,
    isOpenCustomer: false,
    isOpenEditDisc: false,
    isOpenTreatmentDone: false,
    headerDetails: [
      { label: "", width: "70px" },
      {
        label: "Item",
        sortKey: false,
        width: "155px",
        className: "cursor-pointer border border-white text-warning ",
        dblclickFunc: () => this.handleItemHeaderDblClick(true),
      },
      { label: "Qty", width: "32px" },
      {
        label: "Unit Price",
        sortKey: false,
        width: "75px",
      },
      { label: "Disc $", sortKey: false, width: "55px" },
      { label: "D/Price", sortKey: false, width: "70px" },
      { label: "Amt", sortKey: false, width: "72px" },
      { label: "Deposit", sortKey: false, width: "72px" },
      {
        label: "Item Status",
        sortKey: false,
        width: "90px",
        className: "cursor-pointer border border-white text-warning",
        dblclickFunc: () => this.handleItemStatusHeaderDblClick(true),
      },
      {
        label: "S.Staff",
        sortKey: false,
        width: "90px",
        className: "cursor-pointer border border-white text-warning",
        dblclickFunc: () => this.handleStaffSelectionHeaderClick(true),
      },
      {
        label: "T.Staff",
        sortKey: false,
        width: "90px",
        className: "cursor-pointer border border-white text-warning",
        dblclickFunc: () => this.handleStaffSelectionHeaderClick(true),
      },
    ],
    customerOption: [],
    discountFields: {
      discount_per: "",
      discount_amt: "",
      reason: "",
    },
    editCart: {},
    search: "",
    cartId: "",
    isOpenTopup: false,
    exchangeBalance: 0,
    isOpenExchangePayment: false,
    exchangeRemark: "",
    exchangeBalanceAmount: 0,
    exchangeType: false,
    isOpenProductDetail: false,
    isOpenCourseDetail: false,
    isOpenPrepaidDetail: false,
    isOpenDiscountDetail: false,
    isOpenItemStatusDetail: false,
    isOpenStaffSelectionDetail: false,
    visible: false,
    settingData: [],
    defaultStaff: "",
  };

  componentWillMount = () => {
    // this.getCart();
    this.validator = new SimpleReactValidator({
      element: message => (
        <span className="error-message text-danger validNo fs14">
          {message}
        </span>
      ),
      autoForceUpdate: this,
    });
  };
  componentDidMount() {
    this.handleItemSettings();
    this.getdefaultStaffList();
    let { basicApptDetail, selectedCart } = this.props;
    if (basicApptDetail.custId) {
      let { formFields } = this.state;
      formFields["custId"] = basicApptDetail.custId;
      formFields["custName"] = basicApptDetail.custName;
      formFields["cust_refer"] = basicApptDetail.cust_refer;
      this.setState({ formFields });
      this.getCart();
    }
    if (selectedCart) {
    }
  }

  handleItemSettings = () => {
    let { settingData } = this.state;
    this.props.getCommonApi(`userlist/`).then(async key => {
      let { status, data } = key;
      console.log(key, "settingsData cartCreate");
      if (status === 200) {
        settingData = data;
        await this.setState({
          settingData,
          defaultStaff: data.default_loginid,
        }); //settingData.default_loginid
      }
    });
  };

  getdefaultStaffList = () => {
    this.props
      .getCommonApi(`empcartlist/?sales_staff=1&page=1&limit=1000`)
      .then(res => {
        console.log(res, "salesdefaultstaff");
        let salesdefaultStaff = [];
        let { data, status } = res;
        if (status == 200) {
          if (data.dataList)
            for (let key of data.dataList) {
              salesdefaultStaff.push({ value: key.id, label: key.emp_name });
            }
          this.setState({ salesdefaultStaff });
        }
      });
  };

  getCart = () => {
    let { productCard, cartList, cartId } = this.state;
    let { basicApptDetail } = this.props;
    this.setState({ cartList: {}, cartData: [] });
    // this.props.getCommonApi(`itemcart/Check/?sitecodeid=${selected_cstomer.branchId}&cart_date=${"2020-12-11"}&cust_noid=${selected_cstomer.cust_noid}`).then((key) => {
    this.props
      .getCommonApi(
        `itemcart/Check/?cart_date=${dateFormat(
          new Date(),
          "yyyy-mm-dd"
        )}&cust_noid=${basicApptDetail.custId}`
      )
      .then(key => {
        let { status, data, cart_id } = key;
        cartId = cart_id;
        this.setState({ cartId });
        if (data.length !== 0) {
          this.props
            .getCommonApi(
              `itemcart/?cart_date=${dateFormat(
                new Date(),
                "yyyy-mm-dd"
              )}&cust_noid=${basicApptDetail.custId}&cart_id=${cart_id}`
            )
            .then(res => {
              let { status, data } = res;
              console.log(res, "returndatacartitemlist");
              if (status === 200) {
                this.setState({
                  cartList: data,
                  cartData: res,
                  cartId,
                  exchangeBalanceAmount: res.balance.slice(1),
                  exchangeType: res.exchange,
                });
              }
            });
        }
      });
  };

  handleClick = key => {
    if (!this.state.visible) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    if (this.state.visible) {
      let { basicApptDetail } = this.props;
      this.search(basicApptDetail);
    }
    this.setState(prevState => ({
      visible: !prevState.visible,
    }));
  };

  handleOutsideClick = e => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleClick();
  };

  getDateTime = data => {
    let date = new Date(data);
    date = String(date).split(" ");
    let date1 = date[2] + "th " + date[1] + ", " + date[3];
    let time = date[4].split(":");
    let time1 =
      String(Number(time[0]) > 12 ? Number(time[0]) - 12 : time[0]) +
      ":" +
      time[1] +
      (Number(time[0]) > 12 ? "PM" : "AM");
    return time1 + ", " + date1;
  };

  handleSubmit = id => {
    history.push(`/admin/payment/${id}`);
  };

  handleDialog = async () => {
    let {
      isOpenPayment,
      isOpenEditcart,
      isOpenPackage,
      isOpenCustomer,
      isOpenEditDisc,
      isOpenTreatmentDone,
      isOpenTopup,
      isOpenExchangePayment,
      isOpenProductDetail,
      isOpenCourseDetail,
      isOpenPrepaidDetail,
      isOpenDiscountDetail,
      isOpenItemStatusDetail,
      isOpenStaffSelectionDetail,
    } = this.state;
    isOpenPayment = false;
    isOpenEditcart = false;
    isOpenPackage = false;
    isOpenCustomer = false;
    isOpenEditDisc = false;
    isOpenTreatmentDone = false;
    isOpenTopup = false;
    isOpenExchangePayment = false;
    isOpenProductDetail = false;
    isOpenCourseDetail = false;
    isOpenPrepaidDetail = false;
    isOpenDiscountDetail = false;
    isOpenItemStatusDetail = false;
    isOpenStaffSelectionDetail = false;
    await this.setState({
      isOpenPayment,
      isOpenEditcart,
      isOpenPackage,
      isOpenCustomer,
      isOpenEditDisc,
      isOpenTreatmentDone,
      editCart: {},
      isOpenTopup,
      isOpenExchangePayment,
      isOpenProductDetail,
      isOpenCourseDetail,
      isOpenPrepaidDetail,
      isOpenDiscountDetail,
      isOpenItemStatusDetail,
      isOpenStaffSelectionDetail,
    });
    this.getCart();
  };

  handleSearch = async event => {
    // event.persist();
    let { formFields, visible } = this.state;
    formFields.custName = event.target.value;
    await this.setState({ formFields, visible: true });
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(async () => {
        this.search();
      }, 500);
    }
    this.debouncedFn();
  };

  search = searchString => {
    let { formFields } = this.state;
    this.props
      .getCommonApi(`custappt/?search=${formFields.custName}`)
      .then(key => {
        let { status, data } = key;

        if (status === 200) {
          // for (let value of data) {
          //     customerList.push({ value: value.id, label: value.emp_name })
          // }
          this.setState({ customerOption: data });
        }
      });
  };

  handleSelectCustomer = async data => {
    let { formFields } = this.state;
    formFields["custId"] = data.id;
    formFields["custName"] = data.cust_name;
    formFields["cust_refer"] = data.cust_refer;
    await this.setState({
      formFields,
      // isOpenCustomer: false,
      search: "",
      customerOption: [],
    });
    await this.props.updateForm("basicApptDetail", formFields);
    console.log(this.props.basicApptDetail, "sdfsadfasdf");
    this.handleClick();
    this.getCart();
  };

  handleCartCreated = () => {
    this.getCart();
  };

  // handleCheckout = () => {
  //   let { isOpenPayment, cartData, cartList, cartId, exchangeType } =
  //     this.state;
  //   let { basicApptDetail } = this.props;
  //   if (exchangeType && cartData.balance == 0) {
  //     if (cartList && cartList.length > 1) {
  //       let body = {
  //         cust_id: basicApptDetail.custId,
  //         cart_id: cartId,
  //       };
  //       this.handleExchangePayment(body);
  //     } else {
  //       Toast({
  //         type: "error",
  //         message: "Please Add Product for Exchange!",
  //       });
  //     }
  //   } else if (exchangeType && cartData.balance < 0) {
  //     if (cartList && cartList.length > 1) {
  //       this.handleExchangePay();
  //     } else {
  //       Toast({
  //         type: "error",
  //         message: "Please Add Product for Exchange!",
  //       });
  //     }
  //   } else {
  //     isOpenPayment = true;
  //     this.setState({ isOpenPayment });
  //   }
  // };
  handleCheckout = () => {
    let { isOpenPayment, cartData, cartList, cartId, exchangeType } =
      this.state;
    let { basicApptDetail } = this.props;

    if (cartList.length > 0) {
      if (exchangeType && cartData.balance == 0) {
        if (cartList && cartList.length > 1) {
          let body = {
            cust_id: basicApptDetail.custId,
            cart_id: cartId,
          };
          this.handleExchangePayment(body);
        } else {
          Toast({
            type: "error",
            message: "Please Add Product for Exchange!",
          });
        }
      } else if (exchangeType && cartData.balance < 0) {
        if (cartList && cartList.length > 1) {
          this.handleExchangePay();
        } else {
          Toast({
            type: "error",
            message: "Please Add Product for Exchange!",
          });
        }
      } else {
        isOpenPayment = true;
        this.setState({ isOpenPayment });
      }
    }
  };
  handleExchangePayment = body => {
    this.props.commonCreateApi(`exchangeproductconfirm/`, body).then(key => {
      let { status, data } = key;
      if (status == 201) {
        this.getCart();
        history.push(`/admin/billing/print/bill/${data.sa_transacno}`);
      }
    });
  };

  handleDeleteCart = async data => {
    this.props.commonDeleteApi(`itemcart/${data.id}/`).then(() => {
      this.getCart();
    });
  };

  handleEditCart = async data => {
    let { isOpenEditcart, editCart } = this.state;
    isOpenEditcart = true;
    editCart = data;
    this.setState({
      isOpenEditcart,
      editCart,
    });
  };

  handleTreatmentDone = async item => {
    if (item.is_tstaff && Number(item.quantity) == 1) {
      let { isOpenTreatmentDone, editCart } = this.state;
      editCart = item;
      await this.setState({ editCart });
      this.setState({ isOpenTreatmentDone: true });
    }
  };

  handleReduceQuantity = data => {
    let body = { quantity: 1 };
    this.props
      .commonPatchApi(`itemcart/${data.id}/qtyupdate/?check=0`, body)
      .then(() => {
        this.getCart();
      });
  };

  handleAddQuantity = data => {
    let body = { quantity: 1 };
    this.props
      .commonPatchApi(`itemcart/${data.id}/qtyupdate/?check=1`, body)
      .then(() => {
        this.getCart();
      });
  };

  // topup flow
  handleTopup = () => {
    let { isOpenTopup } = this.state;
    isOpenTopup = true;
    this.setState({
      isOpenTopup,
    });
  };

  handleExchangeClick = () => {
    let { cartList, cartId } = this.state;
    let { basicApptDetail } = this.props;
    if (cartList && cartList.length > 0 && basicApptDetail.custId) {
      let body = {
        cust_id: basicApptDetail.custId,
        cart_id: cartId,
      };
      this.props.commonCreateApi(`exchangeproduct/`, body).then(key => {
        let { status } = key;
        if (status == 200) {
          this.getCart();
        }
      });
    }
  };

  handleExchangePay = () => {
    let { isOpenExchangePayment } = this.state;
    isOpenExchangePayment = true;
    this.setState({ isOpenExchangePayment });
  };
  exchangeRemarkChange = async event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleExchangeLessthanZero = async data => {
    let { cartId, exchangeRemark } = this.state;
    let { basicApptDetail } = this.props;
    let body = {
      cust_id: basicApptDetail.custId,
      cart_id: cartId,
      remarks: exchangeRemark,
      return_type: data,
    };
    this.handleExchangePayment(body);
  };

  handleItemHeaderDblClick = data => {
    let { isOpenProductDetail } = this.state;
    isOpenProductDetail = data;
    this.setState({
      isOpenProductDetail,
    });
  };
  handleItemCourseClick = data => {
    if (data.is_course) {
      let { isOpenCourseDetail, editCart } = this.state;
      isOpenCourseDetail = true;
      editCart = data;
      this.setState({
        isOpenCourseDetail,
        editCart,
      });
    }
    if (data.is_prepaid) {
      let { isOpenPrepaidDetail, editCart } = this.state;
      isOpenPrepaidDetail = true;
      editCart = data;
      this.setState({
        isOpenPrepaidDetail,
        editCart,
      });
    }
  };

  handleCardPrepaidClick = data => {
    let { isOpenPrepaidDetail, editCart } = this.state;
    isOpenPrepaidDetail = true;
    editCart = data;
    this.setState({
      isOpenPrepaidDetail,
      editCart,
    });
  };
  handleCardItemCourseClick = data => {
    let { isOpenCourseDetail, editCart } = this.state;
    isOpenCourseDetail = true;
    editCart = data;
    this.setState({
      isOpenCourseDetail,
      editCart,
    });
  };
  handleItemStatusHeaderDblClick = data => {
    let { isOpenItemStatusDetail } = this.state;
    isOpenItemStatusDetail = data;
    this.setState({
      isOpenItemStatusDetail,
    });
  };

  handleDiscountDetailClick = data => {
    if (data.is_disc) {
      if (data.treatment_no == null || Number(data.treatment_no) <= 0) {
        let { isOpenDiscountDetail, editCart } = this.state;
        isOpenDiscountDetail = true;
        editCart = data;
        this.setState({
          isOpenDiscountDetail,
          editCart,
        });
      }
    }
  };
  handleStaffSelectionHeaderClick = data => {
    let { isOpenStaffSelectionDetail } = this.state;
    isOpenStaffSelectionDetail = data;
    this.setState({
      isOpenStaffSelectionDetail,
    });
  };
  handleOpenPackage = async data => {
    let { isOpenPackage, editCart } = this.state;
    isOpenPackage = true;
    editCart = data;
    this.setState({
      isOpenPackage,
      editCart,
    });
  };
  handleClearAllCart = async () => {
    let { cartList } = this.state;
    if (cartList.length > 0) {
      const cartId = this.state.cartList[0].cart_id;

      this.props
        .commonCreateApi(`cartitemdelete/?cart_id=${cartId}`)
        .then(res => {
          this.getCart();
        });
    }
  };
  handleDialogCoursepopup = () => {
    this.getCourseListCheckForReset();
    this.handleDialog();
  };

  getCourseListCheckForReset = () => {
    let { editCart } = this.state;
    this.props
      .getCommonApi(`cartservicecourse/${editCart.id}/`)
      .then(async key => {
        let { status, data } = key;
        console.log(data, "cartcoursepopupforreset");
        if (status == "200") {
          if (data) {
            if (
              !data.is_service &&
              (Number(data.treatment_no) == 0 || data.treatment_no == null)
            ) {
              let body = {
                cart_id: editCart.id,
              };
              this.props
                .commonCreateApi(`cartservicecourse/reset/`, body)
                .then(async key => {
                  let { status, data } = key;
                  console.log(key, "resetresponse");
                });
            }
          }
        }
      });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    let {
      cartList,
      customerOption,
      cartData = {},
      editCart = {},
      isOpenTreatmentDone,
      formFields,
      isOpenPayment,
      isOpenEditDisc,
      isOpenEditcart,
      headerDetails,
      isOpenCustomer,
      cartId,
      isOpenTopup,
      search,
      exchangeBalance,
      isOpenExchangePayment,
      exchangeRemark,
      exchangeBalanceAmount,
      isOpenProductDetail,
      isOpenCourseDetail,
      isOpenPrepaidDetail,
      isOpenDiscountDetail,
      isOpenItemStatusDetail,
      isOpenStaffSelectionDetail,
      visible,
      isOpenPackage,
      defaultStaff,
      salesdefaultStaff,
    } = this.state;
    let { basicApptDetail, t } = this.props;
    return (
      <div className="row new-cart">
        <div className={`col-md-7 col-sm-7 col-12 cart-item`}>
          <div className={`item-list`}>
            <div className="row">
              <div className="input-group col-md-3 col-12 my-2">
                <NormalInput
                  placeholder="Search Customer.."
                  value={formFields.custName}
                  name="custName"
                  onChange={this.handleSearch}
                  onClick={this.handleClick}
                />
                {this.validator.message(
                  "customerName",
                  formFields.custName,
                  "required"
                )}
                <NormalSelect
                  buttonClass={`mt-2`}
                  placeholder="Select Staff"
                  options={salesdefaultStaff}
                  value={defaultStaff} //
                  name="defaultStaff"
                  onChange={this.handleChange}
                  className="customer-name status"
                />
              </div>
              <div className="col-md-9">
                <div className="d-flex flex-wrap justify-content-start">
                  <div className="input-group text-right col-md-3 col-12 my-2">
                    <NormalButton
                      buttonClass={"share w-100"}
                      mainbgrev={true}
                      className="col-12"
                      label="TD"
                      onClick={() =>
                        history.push(
                          `/admin/cart/treatment-done/${basicApptDetail.custId}`
                        )
                      }
                      disabled={!basicApptDetail.custId}
                      leftIcon={tdIcon}
                    />
                  </div>
                  <div className="input-group text-right col-md-3 col-12 my-2">
                    <NormalButton
                      buttonClass={"share w-100"}
                      styleTwo={true}
                      className="col-12"
                      label="Top Up"
                      onClick={() => this.handleTopup()}
                      disabled={!basicApptDetail.custId}
                      leftIcon={topupIcon}
                    />
                  </div>
                  <div className="input-group text-right col-md-3 col-12 my-2">
                    <NormalButton
                      buttonClass={"share w-100"}
                      styleThree={true}
                      className="col-12"
                      label="Bill Ops"
                      onClick={() => history.push("/admin/cart/bill-ops")}
                      disabled={!basicApptDetail.custId}
                      leftIcon={billOpsIcon}
                    />
                  </div>
                  <div className="input-group text-right col-md-3 col-12 my-2">
                    <NormalButton
                      buttonClass={"share w-100"}
                      styleFour={true}
                      className="col-12"
                      label="Profile"
                      onClick={() =>
                        history.push(
                          "/admin/customerplus/" +
                            basicApptDetail.custId +
                            "/details"
                        )
                      }
                      disabled={!basicApptDetail.custId}
                      leftIcon={profileIcon}
                    />
                  </div>
                  {cartList && cartList.length > 0 ? (
                    <div className="input-group text-right col-md-3 col-12 my-2">
                      <NormalButton
                        buttonClass={"share w-100"}
                        styleFive={true}
                        className="col-12"
                        label="Exchange"
                        onClick={this.handleExchangeClick}
                        disabled={!basicApptDetail.custId}
                        leftIcon={exchangeIcon}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <TableWrapper
                headerDetails={headerDetails}
                queryHandler={this.handlePagination}
                // pageMeta={pageMeta}
                // isEmpty={cartList.length === 0 ? true:false}
              >
                {cartList.length > 0
                  ? cartList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div
                              className={`d-flex align-items-center justify-content-center ${
                                item.deposit < 0 ? "text-danger" : ""
                              } `}
                            >
                              <div
                                className="col-4 p-0 pr-4 fs-20 cursor-pointer d-none"
                                onClick={() => this.handleEditCart(item)}
                              >
                                <span className="icon-edit"></span>
                              </div>
                              <div
                                className="col-4 p-0 pr-4 fs-20 cursor-pointer"
                                onClick={() => this.handleDeleteCart(item)}
                              >
                                <span className="icon-delete"></span>
                              </div>
                              {item.itemtype == "PACKAGE" ? (
                                <div
                                  className="col-4 p-0 cursor-pointer"
                                  onClick={() => this.handleOpenPackage(item)}
                                >
                                  <img src={packageIcon} />
                                </div>
                              ) : null}
                            </div>
                          </td>
                          <td className="position-relative status-type">
                            <span className={``}></span>
                            <div
                              onClick={() => this.handleItemCourseClick(item)}
                              className={`d-flex align-items-center justify-content-center cursor-pointer ${
                                item.is_course || item.is_prepaid
                                  ? "text-primary"
                                  : ""
                              } ${item.deposit < 0 ? "text-danger" : ""}`}
                            >
                              {item.itemdesc}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`d-flex align-items-center justify-content-center ${
                                item.deposit < 0 ? "text-danger" : ""
                              } `}
                            >
                              {item.quantity}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`d-flex align-items-center justify-content-center ${
                                item.deposit < 0 ? "text-danger" : ""
                              } `}
                            >
                              {item.price}
                            </div>
                          </td>
                          <td>
                            <div
                              onClick={() =>
                                this.handleDiscountDetailClick(item)
                              }
                              className={`d-flex align-items-center justify-content-center cursor-pointer
                              ${
                                item.is_disc &&
                                (item.treatment_no === null ||
                                  Number(item.treatment_no) <= 0)
                                  ? "text-primary"
                                  : ""
                              }
                              ${item.deposit < 0 ? "text-danger" : ""} `}
                            >
                              {item.discount}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`d-flex align-items-center justify-content-center ${
                                item.deposit < 0 ? "text-danger" : ""
                              } `}
                            >
                              {item.discount_price}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`d-flex align-items-center justify-content-center ${
                                item.deposit < 0 ? "text-danger" : ""
                              } `}
                            >
                              {item.trans_amt}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`d-flex align-items-center justify-content-center ${
                                item.deposit < 0 ? "text-danger" : ""
                              } `}
                            >
                              {item.deposit}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`d-flex align-items-center justify-content-center ${
                                item.deposit < 0 ? "text-danger" : ""
                              } `}
                            >
                              {item.itemstatus_name ? item.itemstatus_name : ""}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`d-flex align-items-center justify-content-center ${
                                item.deposit < 0 ? "text-danger" : ""
                              } `}
                            >
                              {item.sales_staff}
                            </div>
                          </td>
                          <td
                            className={`cursor-pointer`}
                            // onClick={() => this.handleTreatmentDone(item)}
                          >
                            <div
                              className={`d-flex align-items-center justify-content-center
                              ${
                                item.is_tstaff && Number(item.quantity) == 1
                                  ? "text-primary"
                                  : ""
                              } 
                              ${item.deposit < 0 ? "text-danger" : ""} `}
                            >
                              {item.service_staff}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </TableWrapper>
            </div>
            <div className="row payment-section py-2 fs-12">
              <div className="col-6">
                <div className="row">
                  <div className="col-6">{t("Subtotal")}</div>
                  <div className="col-6">
                    $ {cartData.subtotal ? cartData.subtotal : "0"}
                  </div>
                  <div className="col-6">{t("Discount ($)")}</div>
                  <div className="col-6">
                    $ {cartData.discount ? cartData.discount : "0"}
                  </div>
                  <div className="col-6">{t("Transac amount")}</div>
                  <div className="col-6">
                    $ {cartData.trans_amt ? cartData.trans_amt : "0"}
                  </div>
                  <div className="col-6">{t("Deposit")}</div>
                  <div className="col-6">
                    $ {cartData.deposit_amt ? cartData.deposit_amt : "0"}
                  </div>
                  <div className="col-6">{t("Balance")}</div>
                  <div className="col-6">
                    $ {cartData.balance ? cartData.balance : "0"}
                  </div>
                  <div className="col-6">{t("Outstanding")}</div>
                  <div className="col-6">
                    $ {cartData.outstanding ? cartData.outstanding : "0"}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="col-12 text-center fs-24 font-700">
                  {t("Total Billing Amount")}
                </div>
                <div className="col-12 text-center text-orenge fs-24 font-700">
                  $ {cartData.billable_amount ? cartData.billable_amount : "0"}
                </div>
              </div>
              <div className="row col-12 mt-2">
                <div className="col-md-2"></div>
                <div className="col-md-8 col-12">
                  <div className="d-flex flex-wrap justify-content-start">
                    <div className="col-md-4 col-12 p-1">
                      <NormalButton
                        mainbgrev={true}
                        className="col-12"
                        label="ClearAll"
                        onClick={() => this.handleClearAllCart()}
                        leftIcon={clearAllIcon}
                      />
                    </div>
                    <div className="col-md-4 col-12 p-1">
                      <NormalButton
                        mainbg={false}
                        className="col-12 submit-btn"
                        label="Checkout"
                        onClick={() => this.handleCheckout()}
                        leftIcon={checkoutIcon}
                      />
                    </div>
                    <div className="col-md-4 col-12 p-1">
                      <NormalButton
                        mainbgrev={true}
                        className="col-12"
                        label="Trac/Disc"
                        onClick={() => this.setState({ isOpenEditDisc: true })}
                        leftIcon={TransactionIcon}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-sm-5 col-12 pl-2 pr-0">
          <Treatment
            handleCartCreated={this.handleCartCreated}
            handleCardItemCourseClick={editCart =>
              this.handleCardItemCourseClick(editCart)
            }
            handleCardPrepaidClick={editCart =>
              this.handleCardPrepaidClick(editCart)
            }
            defaultStaffId={defaultStaff}
          ></Treatment>
        </div>
        {visible ? (
          <div className="customerSearch-block">
            <div className="d-flex mt-4 table table-header w-100 m-0">
              <div className="col-2">{t("Name")}</div>
              <div className="col-2">{t("Phone")}</div>
              <div className="col-2">{t("Cust Code")}</div>
              <div className="col-2">{t("Reference")}</div>
              <div className="col-3">{t("Email")}</div>
              <div className="col-1">{t("NRIC")}</div>
            </div>
            <div className="response-table w-100">
              {customerOption.length > 0 ? (
                customerOption.map((item, index) => {
                  return (
                    <div
                      className="row m-0 table-body w-100 border"
                      onClick={() => this.handleSelectCustomer(item)}
                      key={index}
                    >
                      <div className="col-2">{item.cust_name}</div>
                      <div className="col-2">{item.cust_phone1}</div>
                      <div className="col-2">{item.cust_code}</div>
                      <div className="col-2">{item.cust_refer}</div>
                      <div className="col-3">{item.cust_email}</div>
                      <div className="col-1">{item.cust_nric}</div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center w-100">
                  {t("No Data are available")}
                </div>
              )}
            </div>
          </div>
        ) : null}
        <NormalModal
          className={"payment-modal"}
          style={{ minWidth: "75%" }}
          modal={isOpenPayment}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          {console.log("sfgdfjhfghjfjgkjk", cartData)}
          <Payment
            cartData={cartData}
            id={basicApptDetail.custId}
            cartId={cartList.length > 0 ? cartList[0].cart_id : ""}
            handleModal={this.handleDialog}
          ></Payment>
        </NormalModal>

        <NormalModal
          className={"edit-cart-modal"}
          style={{ minWidth: "75%" }}
          modal={isOpenEditcart}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <EditCart id={editCart.id} handleModal={this.handleDialog}></EditCart>
        </NormalModal>
        <NormalModal
          className={"package-modal"}
          style={{ minWidth: "75%" }}
          modal={isOpenPackage}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <PackageCart
            id={editCart.id}
            handleModal={this.handleDialog}
          ></PackageCart>
        </NormalModal>
        <NormalModal
          className={"transaction-discount-update"}
          style={{ minWidth: "75%" }}
          modal={isOpenEditDisc}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          {/* <Discount discountFields={()=>{}} handleChange={()=>{}} handleSubmit={()=>{}}></Discount> */}
          {isOpenEditDisc ? (
            <EditDiscount
              id={cartId}
              handleModal={this.handleDialog}
            ></EditDiscount>
          ) : (
            ""
          )}
        </NormalModal>
        <NormalModal
          className={"transaction-done-modal"}
          style={{ minWidth: "55%" }}
          modal={isOpenTreatmentDone}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <TreatmentDone
            id={cartId}
            cartId={editCart.id}
            handleModal={this.handleDialog}
          ></TreatmentDone>
        </NormalModal>
        {/* <NormalModal
          className={"select-category customer-select"}
          style={{ minWidth: "75%" }}
          modal={isOpenCustomer}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close"
            src={closeIcon}
            alt=""
          />
          <div className="row mt-2 mb-5 mx-3">
            <div className="col-12 pl-0 mb-3 fs-18 py-2">Select Customer</div>
            <div className="col-2 pl-0">Search</div>
            <div className="col-5">
              <input
                name="treatment"
                value={search}
                onChange={this.handleSearch}
                className="search m-0 p-0 px-3"
              />
            </div>
            <div className="col-3">
              <NormalButton
                buttonClass={"mx-2 p-0"}
                mainbg={true}
                className="confirm"
                label="Search"
                outline={false}
                onClick={() => this.search(this.state.search)}
              />
            </div>

            <div className="row mt-4 table-header w-100 m-0">
              <div className="col-3">Name</div>
              <div className="col-2">Phone</div>
              <div className="col-3">Cust Code</div>
              <div className="col-4">Email</div>
            </div>
            <div className="response-table w-100">
              {customerOption && customerOption.length > 0 ? (
                customerOption.map((item, index) => {
                  return (
                    <div
                      className="row m-0 table-body w-100"
                      onClick={() => this.handleSelectCustomer(item)}
                      key={index}
                    >
                      <div className="col-3">{item.cust_name}</div>
                      <div className="col-2">{item.cust_phone1}</div>
                      <div className="col-3">{item.cust_code}</div>
                      <div className="col-4">{item.cust_email}</div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center w-100">No Data are available</div>
              )}
            </div>
          </div>
        </NormalModal> */}
        <NormalModal
          className={"transaction-done-modal top-up-modal"}
          style={{ minWidth: "75%" }}
          modal={isOpenTopup}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <Topup
            id={cartId}
            cartId={editCart.id}
            handleModal={this.handleDialog}
          ></Topup>
        </NormalModal>
        <NormalModal
          className={"payment-modal"}
          style={{ minWidth: "60%" }}
          modal={isOpenExchangePayment}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />

          <div className="fs-18 fw-700 mb-3 title">Exchange Payment</div>
          <div className="col-12 pl-0 mb-2 fs-15 fw-500 py-1">
            Extra Amount &nbsp;
            <span className="text-center text-orenge fs-18 font-500">
              $ {exchangeBalanceAmount}
            </span>
            &nbsp;Need to Return to Customer
          </div>

          <div className="row">
            <div className="col-12">
              <label className="text-left text-black common-label-text ">
                Remark
              </label>
              <div className="input-group myp-0">
                <NormalTextarea
                  value={exchangeRemark}
                  name="exchangeRemark"
                  onChange={this.exchangeRemarkChange}
                  className="custome-name px-3 p-0 col-12"
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around pt-3">
            <NormalButton
              mainbg={true}
              className="col-12 fs-13  "
              label="Return Cash"
              onClick={() => {
                this.handleExchangeLessthanZero("Cash");
              }}
            />

            <NormalButton
              mainbg={true}
              className="col-12 fs-13 "
              label="Issue Credit Notes"
              onClick={() => {
                this.handleExchangeLessthanZero("Credit");
              }}
            />

            <NormalButton
              mainbg={true}
              className="col-12 fs-13 "
              label="Forfeit [Cash = 0]"
              onClick={() => {
                this.handleExchangeLessthanZero("Forfeit");
              }}
            />
          </div>
        </NormalModal>
        <NormalModal
          className={"transaction-done-modal top-up-modal"}
          style={{ minWidth: "75%" }}
          modal={isOpenProductDetail}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <ProductDetailsPopup
            id={cartId}
            cartId={editCart.id}
            handleModal={this.handleDialog}
          ></ProductDetailsPopup>
        </NormalModal>
        <NormalModal
          className={"transaction-done-modal top-up-modal"}
          style={{ minWidth: "75%" }}
          modal={isOpenCourseDetail}
          handleModal={() => {}}
        >
          <img
            onClick={this.handleDialogCoursepopup}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <CoursePopup
            id={cartId}
            cartId={editCart.id}
            handleModal={this.handleDialog}
            itemData={editCart}
          ></CoursePopup>
        </NormalModal>
        <NormalModal
          className={"transaction-done-modal top-up-modal"}
          style={{ minWidth: "60%" }}
          modal={isOpenPrepaidDetail}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <PrepaidPopup
            id={cartId}
            cartId={editCart.id}
            handleModal={this.handleDialog}
          ></PrepaidPopup>
        </NormalModal>

        <NormalModal
          className={"transaction-done-modal top-up-modal"}
          style={{ minWidth: "50%" }}
          modal={isOpenDiscountDetail}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <ItemDiscountPopup
            id={cartId}
            cartId={editCart.id}
            handleModal={this.handleDialog}
          ></ItemDiscountPopup>
        </NormalModal>
        <NormalModal
          className={"transaction-done-modal top-up-modal"}
          style={{ minWidth: "75%" }}
          modal={isOpenItemStatusDetail}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <ItemStatusPopup
            id={cartId}
            cartId={editCart.id}
            handleModal={this.handleDialog}
          ></ItemStatusPopup>
        </NormalModal>
        <NormalModal
          className={"transaction-done-modal top-up-modal"}
          style={{ minWidth: "75%" }}
          modal={isOpenStaffSelectionDetail}
          handleModal={this.handleDialog}
        >
          <img
            onClick={this.handleDialog}
            className="close cursor-pointer"
            src={closeIcon}
            alt=""
          />
          <StaffSelectionPopup
            id={cartId}
            cartId={editCart.id}
            handleModal={this.handleDialog}
          ></StaffSelectionPopup>
        </NormalModal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selected_cstomer: state.common.selected_cstomer,
  basicApptDetail: state.appointment.basicApptDetail,
  selectedCart: state.common.selectedCart,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      // getCustomer,
      getCommonApi,
      updateForm,
      commonDeleteApi,
      commonPatchApi,
      commonCreateApi,
    },
    dispatch
  );
};

export const CartNew = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CartNewClass)
);
