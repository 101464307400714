import React, { Component } from "react";
import {
  NormalInput,
  NormalSelect,
  NormalButton,
  NormalDate,
  TableWrapper,
} from "component/common";
import { withTranslation } from "react-i18next";
import { InventoryList } from "./InventoryMainPage/InventoryList";
import { dateFormat } from "service/helperFunctions";
import { getCommonApi } from "redux/actions/common";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

export class ItemUsageListClass extends Component {
  state = {
    itemMemoList: [],
    meta: {},
    itemMemoListHeader: [
      { label: "Treatment" },
      { label: "Usage products" },
      { label: "Qty" },
      { label: "UOM" },
      { label: "Stock" },
      { label: "Item Code" },
      { label: "Link Code" },
    ],
    startDate: new Date(),
    endDate: new Date(),
    Treatment: "",
    inventoryItem: "",
    InventoryList: [],
    TreatmentList: [],
    meta: {},
  };

  componentDidMount() {
    this.getItemMemoList({});
  }
  getItemMemoList = data => {
    let { startDate, endDate, inventoryItem, Treatment, meta } = this.state;
    let { page = 1, limit = 10, search = "" } = data;
    this.props
      .getCommonApi(
        `treatmentusagelist/?from_date=${dateFormat(
          startDate
        )}&to_date=${dateFormat(
          endDate
        )}&item_desc=${inventoryItem}&treatment_desc=${Treatment}&page=${page}&limit=${limit}`
      )
      .then(key => {
        let { status, data } = key;
        if (status === 200) {
          if (data) {
            this.setState({
              itemMemoList: data.dataList,
              meta: data.meta.pagination,
            });
          }
        }
      });
  };

  handlePagination = page => {
    this.getItemMemoList(page);
  };
  handleChange = async ({ target: { value, name } }) => {
    await this.setState({
      [name]: value,
    });
  };
  render() {
    let {
      itemMemoList,
      itemMemoListHeader,
      startDate,
      InventoryList,
      TreatmentList,
      Treatment,
      inventoryItem,
      endDate,
      meta,
    } = this.state;
    let { t } = this.props;
    return (
      <>
        <div className="d-flex flex-wrap py-4">
          <div className="col-md-2 col-12 mb-1">
            <label>{t("From Date")}</label>
            <NormalDate
              value={new Date(startDate)}
              name="startDate"
              type="date"
              onChange={this.handleChange}
              maxDate={new Date()}
              showDisabledMonthNavigation
            />
          </div>
          <div className="col-md-2 col-12 mb-1">
            <label>{t("To Date")}</label>
            <NormalDate
              value={new Date(endDate)}
              name="endDate"
              type="date"
              onChange={this.handleChange}
              minDate={new Date(startDate)}
              maxDate={new Date()}
              showDisabledMonthNavigation
            />
          </div>
          <div className="col-md-2 col-12 mb-1">
            <label>{t("Treatment")}</label>

            <NormalSelect
              placeholder="select"
              options={TreatmentList}
              value={Treatment}
              name="Treatment"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-2 col-12 mb-1">
            <label>{t("Inventory Item")}</label>

            <NormalSelect
              placeholder="select"
              options={InventoryList}
              value={inventoryItem}
              name="inventoryItem"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-2 col-12 mb-1">
            <NormalButton
              buttonClass={`d-flex py-2`}
              mainbgrev={true}
              className="col-12 mt-3"
              label="search"
              onClick={() => this.getItemMemoList({})}
            />
          </div>
        </div>
        <div className="table-container table-responsive mt-3">
          <TableWrapper
            headerDetails={itemMemoListHeader}
            queryHandler={this.handlePagination}
            pageMeta={meta}
          >
            {itemMemoList && itemMemoList.length > 0 ? (
              itemMemoList.map((item, index) => {
                return (
                  <tr className="w-100" key={index}>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {item.id}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {item.item_desc}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {item.qty}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {item.uom}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {item.stock_id}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {item.item_code}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {item.link_code}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="12">
                  <div className="d-flex align-items-center justify-content-center">
                    {`No data`}
                  </div>
                </td>
              </tr>
            )}
          </TableWrapper>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCommonApi,
    },
    dispatch
  );
};

export const ItemUsageList = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ItemUsageListClass)
);
