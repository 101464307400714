const helpers = {
  getUrl() {
    //return "http://103.253.15.218:8001/";
    //return "http://103.253.15.184:8005/";
    //return "http://103.253.15.229:8101/";
    return "https://twoherbs.beautecloud.com/be/";
    //return "https://sensuous.beautecloud.com/be/";
    //return "https://healspa.beautecloud.com/be/";
    //return "https://glamour.beautecloud.com/be/";
    //return "https://browrev.beautecloud.com/be/";
    //return "https://oursolution.beautecloud.com/be/";
    //return "https://healspa.beautecloud.com/be/";
    //return "https://beskin.beautecloud.com/be/";
  },
  getTCMUrl() {
    return "https://crm.soha.edu.sg/apihstcmv10112021/";
    //return "https://crm.soha.edu.sg/apihstcmlive/";
    //return "http://crm.soha.edu.sg/apihstcm/";
    //return "https://crm.soha.edu.sg/apihstcm/";
  },
  getMediaUrl() {
    //return "http://103.253.15.218:8001/";
    //return "http://103.253.15.184:8005/";
    //return "http://103.253.15.229:8101/";
    return "https://twoherbs.beautecloud.com/";
    //return "https://sensuous.beautecloud.com/";
    //return "https://healspa.beautecloud.com/";
    //return "https://glamour.beautecloud.com/";
    //return "https://browrev.beautecloud.com/";
    //return "https://oursolution.beautecloud.com/";
    //return "https://beskin.beautecloud.com/";
  },

  helper2: function (param1) {},
  helper3: function (param1, param2) {},
};

export default helpers;
