import React, { Component } from 'react';
export class DashboardDetails extends Component {
    
    render() {
        return (
             <>

             </>
        );
    }
}